body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a{
  color: inherit;
  cursor: pointer;
  text-decoration: inherit;
}

h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}
h1 {
  font-size: 3.3125rem;
  line-height: 1.15em;
}
h2 {
  font-size: 2.25rem;
  line-height: 1.5em;
}
h3 {
  font-size: 1.5625rem;
  line-height: 1.4em;
}
h4 {
  font-size: 1.125rem;
  line-height: 1.5em;
}
h5 {
  font-size: 1.0625rem;
  line-height: 1.55em;
}
h6 {
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: 500;
}
p {
  font-size: 14px;
  margin: 0 0 10px;
}





























/*
#fdffff - #c0daf1 - background 1
#9bc7ec - background 2
#094663 - font
*/

/******************* SETUP ****************/

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Titillium Web', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background: linear-gradient(to bottom right, #fdffff, #c0daf1);
  background-attachment: fixed; /* This ensures the background is fixed with respect to the viewport */

}


/*
h1
{
  font-size: 45px;
  color: #374657;
  font-weight: 700;
}

h2
{
  font-size: 36px;
  color: #374657;
  font-weight: 700;
}


h2 span.slash
{
  letter-spacing: 8px;
}

h3
{
  font-size: 22px;
  color: #374657;
  font-weight: 700;
}

h4
{
  font-size: 18px;
  color: #374657;
  font-weight: 700;
}

p, li
{
  font-size: 18px;
  color: #747679;
  font-weight: 400;
}

a
{
  color: inherit;
}

header *, footer *{
  text-decoration: none;
}
*/

.MainView
{
  margin-top:60px;
  /*margin-left:240px;*/
}


@media (max-width: 740px) {
  .MainView
  {
    margin-left:0px;
  }
}

.MuiButton-containedPrimary
{
  background:#094663 !important ;
}

.MuiButton-outlinedPrimary 
{
  border:solid #094663 3px !important ;
  color:#094663 !important ;
  font-weight: 600  !important;
}


/******************* ALL *****************/

.ErrorCell
{
  padding-right: 0  !important;
  line-height: 0 !important;
  color:#094663  !important;
  width: 24px !important;
}

/******************* HEADER **********************/

.MuiAppBar-colorPrimary 
{
  background:#fff0 !important;
  transition: none !important;
  box-shadow: none !important;
  color: #094663 !important;
}

.HeaderLogo 
{
  height: 40px;
  margin-right: 15px;
}

a .MuiListItemText-primary {
  color: #333;
}

.DrawFooter {
  width: 100%;
  position: absolute;
  background: #fff;
  padding-top: 5px;
  color:#333 !important;
  font-size: 14px !important;
  bottom: 0 !important;
  padding-Bottom: 5px !important;
  text-align: center !important;
}
.DrawFooter a {
  text-decoration: none !important;
  color:#333 !important;
  font-weight: 600 !important;
}

.TeamMenuListItem * {
  color:#094663 !important
}
.TeamMenuListItem .MuiListItemIcon-root {
  min-width: 36px  !important
}

/******************* LOADING View ****************/
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

.rotating-image {
  animation: rotateImage 0.8s steps(4) infinite;
  width: 100px;
}

@keyframes rotateImage {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}



/******************* 404 View ****************/

.View404
{
  text-align: center;
  padding: 30px 20px;
}

.View404 h1
{
  font-size: 20px;
}

.View404 p
{
  font-size: 16px;
}

/******************* Login View ******************/

.LoginView
{
  padding: 50px 0px;

  text-align: center;
}


.LoginView h1
{
  font-size: 40px;
  margin-bottom: 0;
  color:#094663ff;
}

.LoginView h2
{
  margin-top: 0;
  margin-bottom: 50px;
}

.LoginView img
{
  max-width: 150px;
}


/************* MAP **************/
#map 
{
  position: absolute;
  top:56px;
  bottom:0;
  left:240px;
  right:0;

}


@media (max-width: 740px) {
  #map
  {
    left:0;
  }
}


















/*Css Code from Bootstrap 3.3.7* /
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
display: none !important;
}
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
display: none !important;
}
@media (max-width: 767px) {
.visible-xs {
display: block !important;
}
table.visible-xs {
display: table !important;
}
tr.visible-xs {
display: table-row !important;
}
th.visible-xs,
td.visible-xs {
display: table-cell !important;
}
}
@media (max-width: 767px) {
.visible-xs-block {
display: block !important;
}
}
@media (max-width: 767px) {
.visible-xs-inline {
display: inline !important;
}
}
@media (max-width: 767px) {
.visible-xs-inline-block {
display: inline-block !important;
}
}
@media (min-width: 768px) and (max-width: 991px) {
.visible-sm {
display: block !important;
}
table.visible-sm {
display: table !important;
}
tr.visible-sm {
display: table-row !important;
}
th.visible-sm,
td.visible-sm {
display: table-cell !important;
}
}
@media (min-width: 768px) and (max-width: 991px) {
.visible-sm-block {
display: block !important;
}
}
@media (min-width: 768px) and (max-width: 991px) {
.visible-sm-inline {
display: inline !important;
}
}
@media (min-width: 768px) and (max-width: 991px) {
.visible-sm-inline-block {
display: inline-block !important;
}
}
@media (min-width: 960px) and (max-width: 1280px) {
.visible-md {
display: block !important;
}
table.visible-md {
display: table !important;
}
tr.visible-md {
display: table-row !important;
}
th.visible-md,
td.visible-md {
display: table-cell !important;
}
}
@media (min-width: 960px) and (max-width: 1280px) {
.visible-md-block {
display: block !important;
}
}
@media (min-width: 960px) and (max-width: 1280px) {
.visible-md-inline {
display: inline !important;
}
}
@media (min-width: 960px) and (max-width: 1280px) {
.visible-md-inline-block {
display: inline-block !important;
}
}
@media (min-width: 1200px) {
.visible-lg {
display: block !important;
}
table.visible-lg {
display: table !important;
}
tr.visible-lg {
display: table-row !important;
}
th.visible-lg,
td.visible-lg {
display: table-cell !important;
}
}
@media (min-width: 1200px) {
.visible-lg-block {
display: block !important;
}
}
@media (min-width: 1200px) {
.visible-lg-inline {
display: inline !important;
}
}
@media (min-width: 1200px) {
.visible-lg-inline-block {
display: inline-block !important;
}
}
@media (max-width: 767px) {
.hidden-xs {
display: none !important;
}
}
@media (min-width: 768px) and (max-width: 991px) {
.hidden-sm {
display: none !important;
}
}
@media (min-width: 960px) and (max-width: 1280px) {
.hidden-md {
display: none !important;
}
}
@media (min-width: 1200px) {
.hidden-lg {
display: none !important;
}
}



pre {
  background: #eee;
  padding: 10px 20px;
}

*/